<template>
  <div class="swiper-container about-swiper">
    <div class="swiper-wrapper">
      <div
        class="swiper-slide"
        v-for="aboutbox in aboutData"
        :key="aboutbox.title"
        :class="{ 'wide': aboutbox.wide }"
      >
        <div class="title">
          {{aboutbox.title}}
        </div>
        <div class="content_box">

          <div class="content">
            <div class="img">
              <img
                :src="aboutbox.url"
                alt=""
              >
            </div>
            <div class="text">
              {{aboutbox.text}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-button-next swiper-button-white"></div>
    <div class="swiper-button-prev swiper-button-white"></div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/dist/css/swiper.min.css';
import 'swiper/dist/js/swiper.min.js';

export default {
  props: ['screenWidth'],
  data() {
    return {
      aboutData: [
        {
          wide: true,
          title: "2017年公司成立",
          text: "贵州智联云弛软件科技有限公司成立了，来到了爽爽的贵州！",
          url: require('../../assets/image/about_swiper1.jpg'),
        },
        {
          wide: false,
          title: "2018年",
          text: "我们在不断的努力，不断的进步，不断打响自己的名气并申请公司软著。",
          url: require('../../assets/image/about_swiper2.png'),
        },
        {
          wide: true,
          title: "2019年",
          text: "公司搬家了。我们搬家到了贵州省-贵阳市-观山湖区-八音路2号-贵阳科技大厦A座-9楼。",
          url: require('../../assets/image/about_swiper3.jpg'),
        },
        {
          wide: true,
          title: "2019年9月",
          text: "2019年9月，公司经审核通过获得高新技术企业证书！！",
          url: require('../../assets/image/about_swiper4.jpg'),
        },
        {
          wide: true,
          title: "2019年12月",
          text: "2019年12月，公司经贵州省信息技术服务业协会评估获得双软证书！",
          url: require('../../assets/image/about_swiper5.jpg'),
        },
      ],
      View: 0,
    };
  },
  watch: {
    screenWidth: {
      handler() {
        this.switch();
      },
      deep: true
    }
  },
  mounted() {
    this.switch();
  },
  methods: {
    // 大图轮播
    switch() {
      // console.log(this.screenWidth);
      if (this.screenWidth <= 425) {
        this.View = 1;
      } else if (this.screenWidth <= 768) {
        this.View = 2;
      } else {
        this.View = 3;
      }
      setTimeout(() => {
const swiper = new Swiper('.about-swiper', {
        slidesPerView: this.View,
        slidesPerColumn: 1,
        spaceBetween: 20,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
      swiper.slideNext();
      },100)
      
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-container {
  background: url("../../assets/image/time_x1.png") no-repeat center 4px;
  background-size: 113%;
  .swiper-wrapper {
    padding-top: 40px;
    height: auto;
    .swiper-slide {
      position: relative;
      width: 275px;
      height: auto;
      cursor: pointer;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      .title {
        color: #fff;
        text-align: center;
        font-weight: 400;
        font-size: 18px;
        line-height: 54px;
      }
      .content_box {
        background: url("../../assets/image/history_ico.png") no-repeat center
          top;
        background-size: 100%;
        width: 80%;
        max-height: 150px;
        padding-top: 10px;
        .content {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          border: 1px solid white;
          border-top: 0;
          padding: 5px;
          .img {
            max-width: 40%;
            img {
              width: 100%;
            }
          }
          .text {
            width: auto;
            color: #fff;
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }
    .wide {
      .content_box {
        background: url("../../assets/image/history_ico.png") no-repeat center
          top;
        background-size: 100%;
        width: 80%;
        max-height: 150px;
        padding-top: 13px;
        .content {
          display: flex;
          flex-flow: column nowrap;
          justify-content: space-between;
          border: 1px solid white;
          border-top: 0;
          padding: 5px;
          .img {
            max-width: 100%;
            max-height: 100px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .text {
            width: 100%;
          }
        }
      }
    }
  }
}
@media (max-width: 1280px) {
  .swiper-slide .title {
    top: 17%;
  }
  .swiper-slide .title h1 {
    color: white;
    font-size: 2em;
    font-style: normal;
    font-family: inherit;
    font-weight: 500;
  }
  .swiper-slide .title p {
    color: white;
    line-height: 180%;
    font-size: 1.3em;
    margin-top: 40px;
    font-style: normal;
    font-family: inherit;
    font-weight: 300;
  }
}
@media (max-width: 1024px) {
  .swiper-container {
    background: url("../../assets/image/time_x1.png") no-repeat center 4px;
    background-size: 113%;
    .swiper-wrapper {
      padding-top: 30px;
      height: auto;
      .swiper-slide {
        position: relative;
        width: 275px;
        height: auto;
        cursor: pointer;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        padding-bottom: 10px;
        .title {
          color: #fff;
          text-align: center;
          font-weight: 400;
          font-size: 18px;
          line-height: 16px;
        }
        .content_box {
          background: url("../../assets/image/history_ico.png") no-repeat center
            top;
          background-size: 100%;
          width: 79%;
          max-height: 150px;
          padding-top: 3%;
          margin-top: 20px;
          .content {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            border: 1px solid white;
            border-top: 0;
            padding: 5px;
            .img {
              max-width: 40%;
              img {
                width: 100%;
              }
            }
            .text {
              width: 150px;
              color: #fff;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
      .wide {
        .content_box {
          background: url("../../assets/image/history_ico.png") no-repeat center
            top;
          background-size: 100%;
          width: 80%;
          max-height: 150px;
          padding-top: 10px;
          .content {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            border: 1px solid white;
            border-top: 0;
            padding: 5px;
            .img {
              max-width: 100%;
              max-height: 100px;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .text {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .swiper-container {
    background: url("../../assets/image/time_x2.png") no-repeat center 4px;
    background-size: 130%;
    .swiper-wrapper {
      padding-top: 30px;
      height: auto;
      .swiper-slide {
        position: relative;
        width: 275px;
        height: auto;
        cursor: pointer;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        padding-bottom: 10px;
        .title {
          color: #fff;
          text-align: center;
          font-weight: 400;
          font-size: 18px;
          line-height: 16px;
        }
        .content_box {
          background: url("../../assets/image/history_ico.png") no-repeat center
            top;
          background-size: 100%;
          width: 79%;
          max-height: 150px;
          padding-top: 3%;
          margin-top: 20px;
          .content {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            border: 1px solid white;
            border-top: 0;
            padding: 5px;
            .img {
              max-width: 40%;
              img {
                width: 100%;
              }
            }
            .text {
              width: 55%;
              color: #fff;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
      .wide {
        .content_box {
          background: url("../../assets/image/history_ico.png") no-repeat center
            top;
          background-size: 100%;
          width: 80%;
          max-height: 150px;
          padding-top: 10px;
          .content {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            border: 1px solid white;
            border-top: 0;
            padding: 5px;
            .img {
              max-width: 100%;
              max-height: 100px;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .text {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 425px) {
  .swiper-container {
    background: url("../../assets/image/time_x1.png") no-repeat center 4px;
    background-size: 113%;
    .swiper-wrapper {
      padding-top: 30px;
      height: auto;
      .swiper-slide {
        position: relative;
        width: 275px;
        height: auto;
        cursor: pointer;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        padding-bottom: 10px;
        .title {
          color: #fff;
          text-align: center;
          font-weight: 400;
          font-size: 18px;
          line-height: 16px;
        }
        .content_box {
          background: url("../../assets/image/history_ico.png") no-repeat center
            top;
          background-size: 100%;
          width: 79%;
          max-height: 180px;
          padding-top: 3%;
          margin-top: 20px;
          .content {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            border: 1px solid white;
            border-top: 0;
            padding: 5px;
            .img {
              max-width: 40%;
              img {
                width: 100%;
              }
            }
            .text {
              width: 150px;
              color: #fff;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
      .wide {
        .content_box {
          background: url("../../assets/image/history_ico.png") no-repeat center
            top;
          background-size: 100%;
          width: 80%;
          max-height: 180px;
          padding-top: 10px;
          .content {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            border: 1px solid white;
            border-top: 0;
            padding: 5px;
            .img {
              max-width: 100%;
              max-height: 100px;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .text {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.hide {
  opacity: 0;
}
</style>

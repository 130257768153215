<template>
  <div id="home">
    <!-- 轮播图 -->
    <Swiper :displays="displays" />
    <!-- 公司资历 -->
    <div class="qualifications">
      <div class="qualifications_box">
        <div
          class="qualifications_title"
          v-for="qualification in qualifications"
          :key="qualification.title"
          :title="qualification.title"
        >
          <div class="bg"></div>
          <div class="qualificationsbox">
            <img :src="qualification.url" alt="" :title="qualification.title" />
            <p class="qualifications_text">{{ qualification.title }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 公司案例 -->
    <div class="case">
      <div class="case_box">
        <p class="public_title">
          <span class="title_en">new cases</span><br />
          <span class="title_name">最新案例</span>&nbsp;&nbsp;
          <span class="title_introduction"></span>
        </p>
        <CaseSwiper :displays="displays" :screenWidth="screenWidth" />
      </div>
    </div>
    <!-- 关于我们 -->
    <div class="about">
      <div class="about_box">
        <div class="about_header">
          <p class="public_title">
            <span class="title_en">who we are</span><br />
            <span class="title_name">关于我们</span>&nbsp;&nbsp;
            <span class="title_introduction"
              >我们专注设计，只做一件事：持续为客户创造价值。</span
            >
          </p>
          <div class="about_router">
            <div class="active" @mouseover="mouSeover(0)" @click="mouSeover(0)">
              简介
            </div>
            <div @click="mouSeover(1)" @mouseover="mouSeover(1)">历程</div>
            <div @click="mouSeover(2)" @mouseover="mouSeover(2)">活动</div>
          </div>
        </div>
        <div class="about_content" :style="{ left: activeLeft }">
          <div class="abuot_introduction">
            <div class="abuot_img">
              <img src="../assets/image/about_img.png" alt="" />
            </div>
            <div class="introduction_content">
              <div class="introduction_img">
                <img src="../assets/image/about_ico1.png" alt="" />
                <img src="../assets/image/about_ico2.png" alt="" />
                <img src="../assets/image/about_ico3.png" alt="" />
                <img src="../assets/image/about_ico4.png" alt="" />
              </div>
              <p>
                贵州智联云弛软件科技有限公司于2017年05月18日在贵州贵安新区行政审批局注册成立，注册资金1000万元。
                是专业从事行业应用软件开发与大数据处理的集研究、开发、运维为一体的高科技企业，业务涵盖通信、政
                府、金融等行业。为客户提供信息管理、业务支撑、智能化产品和行业解决方案及运营维护等服务。
              </p>
            </div>
          </div>
          <AboutSwiper :screenWidth="screenWidth" />
          <ActivitySwiper :screenWidth="screenWidth" />
        </div>
      </div>
    </div>
    <!-- 服务客户 -->
    <div class="customer_box">
      <div class="customer">
        <p class="customer_title">
          <span class="title_en">cusromeres</span><br />
          <span class="title_name">服务客户</span>
          <span class="title_introduction"></span>
        </p>
        <div class="customer_content">
          <div
            class="customer_label"
            v-for="customer in customers"
            :key="customer.url"
          >
            <img :src="customer.url" alt="" />
            <div class="wk_case_xian">
              <span class="wk_span_1"></span>
              <span class="wk_span_2"></span>
              <span class="wk_span_3"></span>
              <span class="wk_span_4"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 联系我们 -->
    <div class="contact_box">
      <div class="contact">
        <div class="contact_img">
          <img src="../assets/image/contact_img.png" alt="" />
        </div>
        <div class="contact_content">
          <p class="public_title">
            <span class="title_en">contact us</span><br />
            <span class="title_name">联系我们</span>&nbsp;&nbsp;
            <span class="title_introduction"
              >尊敬的客户，我们24小时竭诚为您服务</span
            >
          </p>
          <ul>
            <li>
              <div class="img">
                <img src="../assets/image/cont_a.png" alt="" />
              </div>
              <span>服务热线：15519158066</span>
            </li>
            <li>
              <div class="img mailbox"></div>
              <span>15519158066@wo.cn</span>
            </li>
            <li>
              <div class="img address"></div>
              <span>
                贵州省贵阳市国家高新技术产业开发区金阳科技产业园贵阳科技大厦A栋9楼
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 页尾 -->
    <Footer :displays="displays" />
  </div>
</template>

<script>
import $ from "jquery";
import Swiper from "../components/Home/Swiper.vue";
import CaseSwiper from "../components/Home/CaseSwiper.vue";
import AboutSwiper from "../components/Home/AboutSwiper.vue";
import ActivitySwiper from "../components/Home/ActivitySwiper.vue";
import Footer from "../components/Public/Footer.vue";

export default {
  name: "Home",
  components: {
    Swiper,
    CaseSwiper,
    AboutSwiper,
    ActivitySwiper,
    Footer,
  },
  data() {
    return {
      qualifications: [
        {
          title: "高新技术企业证书",
          url: require("../assets/image/qualifications1.png"),
        },
        {
          title: "软件企业证书",
          url: require("../assets/image/qualifications2.png"),
        },
        {
          title: "环境管理体系认证证书",
          url: require("../assets/image/qualifications3.png"),
        },
        {
          title: "职业健康安全管理体系认证",
          url: require("../assets/image/qualifications4.png"),
        },
        {
          title: "质量管理体系认证证书",
          url: require("../assets/image/qualifications5.png"),
        },
      ],
      customers: [
        {
          url: require("../assets/image/customer1.png"),
        },
        {
          url: require("../assets/image/customer2.png"),
        },
        {
          url: require("../assets/image/customer3.png"),
        },
        {
          url: require("../assets/image/customer4.png"),
        },
        {
          url: require("../assets/image/customer5.png"),
        },
        {
          url: require("../assets/image/customer6.png"),
        },
        {
          url: require("../assets/image/customer7.png"),
        },
      ],
      activeLeft: "0px",
      screenWidth: 0,
      displays: true,
    };
  },
  watch: {
    screenWidth(val) {
      if (val <= 1024) {
        this.displays = false;
      } else {
        this.displays = true;
      }
    },
  },
  mounted() {
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
  methods: {
    mouSeover(idx) {
      $(`.about_router div:eq(${idx})`).addClass("active");
      $(`.about_router div:eq(${idx})`).siblings().removeClass("active");
      this.activeLeft = `-${idx * 100}%`;
    },
  },
};
</script>

<style lang="scss">
#home {
  padding-top: 60px;
  height: auto;
  .public_title {
    color: white;
    text-align: left;
    padding-bottom: 15px;
    .title_en {
      font-family: "Agency FB";
      font-size: 50px;
      font-weight: bolder;
      text-transform: uppercase;
    }
    .title_name {
      font-family: "幼圆";
      font-size: 30px;
    }
  }
  .qualifications {
    width: 100vw;
    height: 800px;
    background: url("../assets/image/bg2.png") no-repeat;
    background-size: 100% 100%;
    .qualifications_box {
      width: 85vw;
      height: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-content: center;
      justify-content: center;
      margin: 0 auto;
      .qualifications_title {
        position: relative;
        width: 25%;
        .bg {
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          height: 0;
          transition: all 0.5s;
        }
        .qualificationsbox {
          height: 100%;
          width: 100%;
          display: flex;
          flex-flow: column wrap;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          .qualifications_text {
            width: 100%;
            color: white;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-weight: 400;
            font-size: 19px;
          }
        }
        &:nth-child(1) {
          .bg {
            background-color: #65a2e1;
          }
        }
        &:nth-child(2) {
          .bg {
            background-color: #1599a5;
          }
        }
        &:nth-child(3) {
          .bg {
            background-color: #5e904d;
          }
        }
        &:nth-child(4) {
          .bg {
            background-color: #5d4378;
          }
        }
        &:nth-child(5) {
          .bg {
            background-color: #ff5e00;
          }
        }
        &:hover {
          .bg {
            height: 100%;
          }
        }
      }
    }
  }
  .case {
    width: 100vw;
    height: 1000px;
    background: url("../assets/image/bg3.png") no-repeat;
    background-size: 100% 100%;
    .case_box {
      width: 85vw;
      margin: 0 auto;
      padding-top: 150px;
      .case_title {
        margin-bottom: 20px;
      }
    }
  }
  .about {
    width: 100vw;
    height: 900px;
    background: url("../assets/image/bg4.jpg") no-repeat;
    background-size: 100% 100%;
    .about_box {
      width: 85vw;
      height: 100%;
      overflow: hidden;
      margin: auto;
      .about_header {
        width: 85vw;
        margin-bottom: 20px;
        padding-top: 150px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: flex-end;
        .about_router {
          padding-bottom: 15px;
          display: flex;
          div {
            width: 80px;
            height: 42px;
            background: #263551;
            color: #fff;
            text-align: center;
            font-size: 16px;
            line-height: 42px;
            cursor: pointer;
            margin-left: 5px;
          }
          .active {
            background-color: #334871;
          }
        }
      }
      .about_content {
        width: 100 * 3%;
        height: auto;
        display: flex;
        position: relative;
        overflow: hidden;
        transition: all 1s;
        .abuot_introduction {
          width: 85vw;
          height: 100%;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          .abuot_img {
            width: 35%;
            text-align: center;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .introduction_content {
            width: 55%;
            height: 100%;
            .introduction_img {
              display: flex;
              flex-flow: row nowrap;
              justify-content: space-between;
              padding: 15px 0;
            }
            p {
              color: #fff;
              font-size: 18px;
              line-height: 26px;
            }
          }
        }
        .about-swiper {
          display: inline-block;
          width: 85vw;
          height: 300px;
        }
        .activity-swiper {
          width: 85vw;
          height: 100%;
        }
      }
    }
  }
  .customer_box {
    width: 100vw;
    height: 700px;
    background: url("../assets/image/bg5.jpg") no-repeat;
    .customer {
      width: 85vw;
      margin: auto;
      padding-top: 150px;
      .customer_title {
        color: white;
        text-align: center;
        border-bottom: 3px solid #0ddfba;
        padding-bottom: 15px;
        .title_en {
          font-family: "Agency FB";
          font-size: 50px;
          font-weight: bolder;
          text-transform: uppercase;
        }
        .title_name {
          font-family: "幼圆";
          font-size: 30px;
        }
      }
      .customer_content {
        width: 80vw;
        height: auto;
        margin: auto;
        background: rgba(255, 255, 255, 0.068);
        padding: 1vw 0 0 1vw;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        .customer_label {
          position: relative;
          width: 17%;
          height: 100px;
          margin-right: 2%;
          margin-bottom: 2%;
          padding: 5px;
          img {
            width: 100%;
            height: 100%;
          }
          .wk_case_xian {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            span {
              position: absolute;
              background: #0ddfba;
              -webkit-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
              -moz-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
              -o-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
              transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            }
            .wk_span_1 {
              top: 0;
              left: 0;
              width: 0;
              height: 2px;
            }
            .wk_span_2 {
              top: 0;
              right: 0;
              width: 2px;
              height: 0;
            }
            .wk_span_3 {
              right: 0;
              bottom: 0;
              width: 0;
              height: 2px;
            }
            .wk_span_4 {
              bottom: 0;
              left: 0;
              width: 2px;
              height: 0;
            }
          }
          &:hover {
            .wk_case_ico {
              opacity: 1;
              -webkit-opacity: 1;
              -moz-opacity: 1;
            }
            .wk_span_1,
            .wk_span_3 {
              width: 100%;
            }
            .wk_span_2,
            .wk_span_4 {
              height: 100%;
            }
          }
        }
        &::after {
          content: "";
          flex-grow: 99;
        }
      }
    }
  }
  .contact_box {
    width: 100vw;
    height: 580px;
    background: url("../assets/image/bg6.jpg") no-repeat;
    padding-top: 150px;
    .contact {
      width: 85vw;
      height: 550px;
      margin: auto;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-around;
      .contact_content {
        width: 600px;
        ul {
          li {
            height: 60px;
            color: #ccc;
            font-size: 18px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            cursor: pointer;
            .img {
              width: 49px;
              height: 44px;
              margin-right: 15px;
              transition: all 0.2s;
              &.mailbox {
                background: url("../assets/image/cont_c.png") no-repeat;
              }
              &.address {
                background: url("../assets/image/cont_d.png") no-repeat;
              }
            }
            &:hover {
              .img {
                &.mailbox {
                  background: url("../assets/image/cont_c.png") no-repeat 0 -44px;
                }
                &.address {
                  background: url("../assets/image/cont_d.png") no-repeat 0 -44px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1160px) {
  #home {
    padding-top: 60px;
    height: auto;
    .public_title {
      color: white;
      text-align: left;
      padding-bottom: 15px;
      .title_en {
        font-family: "Agency FB";
        font-size: 50px;
        font-weight: bolder;
        text-transform: uppercase;
      }
      .title_name {
        font-family: "幼圆";
        font-size: 30px;
      }
    }
    .qualifications {
      width: 100vw;
      height: 800px;
      background: url("../assets/image/bg2.jpg") no-repeat;
      background-size: 100% 100%;
      .qualifications_box {
        width: 90vw;
        height: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-content: center;
        justify-content: center;
        margin: 0 auto;
        .qualifications_title {
          position: relative;
          width: 23%;
          .bg {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            height: 0;
            transition: all 0.5s;
          }
          .qualificationsbox {
            height: 100%;
            width: 100%;
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            img {
              width: 80%;
            }
            .qualifications_text {
              width: 100%;
              color: white;
              height: 50px;
              line-height: 50px;
              text-align: center;
              font-weight: 400;
              font-size: 15px;
            }
          }
          &:nth-child(1) {
            .bg {
              background-color: #ff00dd;
            }
          }
          &:nth-child(2) {
            .bg {
              background-color: #1599a5;
            }
          }
          &:nth-child(3) {
            .bg {
              background-color: #5e904d;
            }
          }
          &:nth-child(4) {
            .bg {
              background-color: #5d4378;
            }
          }
          &:nth-child(5) {
            .bg {
              background-color: #ff5e00;
            }
          }
          &:hover {
            .bg {
              height: 100%;
            }
          }
        }
      }
    }
    .case {
      width: 100vw;
      height: 900px;
      background: url("../assets/image/bg3.jpg") no-repeat;
      background-size: 100% 100%;
      .case_box {
        width: 80%;
        margin: 0 auto;
        padding-top: 150px;
        .case_title {
          margin-bottom: 20px;
        }
      }
    }
    .about {
      width: 100vw;
      height: 800px;
      background: url("../assets/image/bg4.jpg") no-repeat;
      background-size: 100% 100%;
      .about_box {
        width: 80%;
        height: 100%;
        overflow: hidden;
        margin: auto;
        .about_header {
          width: 100%;
          margin-bottom: 20px;
          padding-top: 150px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: flex-end;
          .about_router {
            padding-bottom: 15px;
            display: flex;
            div {
              width: 80px;
              height: 42px;
              background: #263551;
              color: #fff;
              text-align: center;
              font-size: 16px;
              line-height: 42px;
              cursor: pointer;
              margin-left: 5px;
            }
            .active {
              background-color: #334871;
            }
          }
        }
        .about_content {
          width: 100 * 3%;
          height: 400px;
          display: flex;
          position: relative;
          overflow: hidden;
          transition: all 1s;
          .abuot_introduction {
            width: 50%;
            height: 100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            .abuot_img {
              width: 40%;
              text-align: center;
              img {
                width: 100%;
                height: auto;
              }
            }
            .introduction_content {
              width: 55%;
              height: 100%;
              .introduction_img {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                padding: 15px 0;
              }
              p {
                color: #fff;
                font-size: 18px;
                line-height: 26px;
              }
            }
          }
          .about-swiper {
            display: inline-block;
            width: 50%;
            height: 100%;
          }
          .activity-swiper {
            display: inline-block;
            width: 50%;
            height: 100%;
          }
        }
      }
    }
    .customer_box {
      width: 100vw;
      height: 700px;
      background: url("../assets/image/bg5.jpg") no-repeat;
      .customer {
        width: 80vw;
        margin: auto;
        padding-top: 150px;
        .customer_title {
          color: white;
          text-align: center;
          border-bottom: 3px solid #0ddfba;
          padding-bottom: 15px;
          .title_en {
            font-family: "Agency FB";
            font-size: 50px;
            font-weight: bolder;
            text-transform: uppercase;
          }
          .title_name {
            font-family: "幼圆";
            font-size: 30px;
          }
        }
        .customer_content {
          width: 78vw;
          height: auto;
          margin: auto;
          background: rgba(255, 255, 255, 0.068);
          padding: 1vw 0 0 1vw;
          display: flex;
          flex-flow: row wrap;
          justify-content: flex-start;
          .customer_label {
            position: relative;
            width: 16%;
            height: 70px;
            margin-right: 2%;
            margin-bottom: 2%;
            padding: 5px;
            img {
              width: 100%;
            }
            .wk_case_xian {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              span {
                position: absolute;
                background: #0ddfba;
                -webkit-transition: all 0.3s
                  cubic-bezier(0.25, 0.46, 0.45, 0.94);
                -moz-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
                -o-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
                transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
              }
              .wk_span_1 {
                top: 0;
                left: 0;
                width: 0;
                height: 2px;
              }
              .wk_span_2 {
                top: 0;
                right: 0;
                width: 2px;
                height: 0;
              }
              .wk_span_3 {
                right: 0;
                bottom: 0;
                width: 0;
                height: 2px;
              }
              .wk_span_4 {
                bottom: 0;
                left: 0;
                width: 2px;
                height: 0;
              }
            }
            &:hover {
              .wk_case_ico {
                opacity: 1;
                -webkit-opacity: 1;
                -moz-opacity: 1;
              }
              .wk_span_1,
              .wk_span_3 {
                width: 100%;
              }
              .wk_span_2,
              .wk_span_4 {
                height: 100%;
              }
            }
          }
          &::after {
            content: "";
            flex-grow: 99;
          }
        }
      }
    }
    .contact_box {
      width: 100vw;
      height: 580px;
      background: url("../assets/image/bg6.jpg") no-repeat;
      padding-top: 150px;
      .contact {
        width: 80%;
        height: 550px;
        margin: auto;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        .contact_content {
          width: 600px;
          ul {
            li {
              height: 60px;
              color: #ccc;
              font-size: 18px;
              display: flex;
              flex-flow: row nowrap;
              align-items: center;
              cursor: pointer;
              .img {
                width: 44px;
                height: 44px;
                margin-right: 15px;
                transition: all 0.2s;
                &.mailbox {
                  background: url("../assets/image/cont_c.png") no-repeat;
                }
                &.address {
                  background: url("../assets/image/cont_d.png") no-repeat;
                }
              }
              span {
                width: 75%;
              }
              &:hover {
                .img {
                  &.mailbox {
                    background: url("../assets/image/cont_c.png") no-repeat 0 -44px;
                  }
                  &.address {
                    background: url("../assets/image/cont_d.png") no-repeat 0 -44px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  #home {
    padding-top: 60px;
    height: auto;
    .public_title {
      color: white;
      text-align: left;
      padding-bottom: 15px;
      .title_en {
        font-family: "Agency FB";
        font-size: 40px;
        font-weight: bolder;
        text-transform: uppercase;
      }
      .title_name {
        font-family: "幼圆";
        font-size: 20px;
      }
      .title_introduction {
        font-family: "幼圆";
        font-size: 15px;
      }
    }
    .qualifications {
      width: 100vw;
      height: 800px;
      background: url("../assets/image/bg2.jpg") no-repeat;
      background-size: 100% 100%;
      .qualifications_box {
        width: 90vw;
        height: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        align-items: center;
        margin: 0 auto;
        .qualifications_title {
          position: relative;
          width: 25%;
          height: 100%;
          .bg {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            height: 0;
            transition: all 0.5s;
          }
          .qualificationsbox {
            height: 100%;
            width: 100%;
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            .qualifications_text {
              width: 100%;
              color: white;
              height: 30px;
              line-height: 30px;
              text-align: center;
              font-weight: 400;
              font-size: 19px;
            }
          }
          &:nth-child(1) {
            .bg {
              background-color: #ff00dd;
            }
          }
          &:nth-child(2) {
            .bg {
              background-color: #1599a5;
            }
          }
          &:nth-child(3) {
            .bg {
              background-color: #5e904d;
            }
          }
          &:nth-child(4) {
            .bg {
              background-color: #5d4378;
            }
          }
          &:nth-child(5) {
            .bg {
              background-color: #ff5e00;
            }
          }
          &:hover {
            .bg {
              height: 100%;
            }
          }
        }
      }
    }
    .case {
      width: 100vw;
      height: 500px;
      background: url("../assets/image/bg3.jpg") no-repeat;
      background-size: 100% 100%;
      .case_box {
        width: 80%;
        margin: 0 auto;
        padding-top: 50px;
        .case_title {
          margin-bottom: 20px;
        }
      }
    }
    .about {
      width: 100vw;
      height: 850px;
      background: url("../assets/image/bg4.jpg") no-repeat;
      background-size: 100% 100%;
      .about_box {
        width: 90%;
        height: 100%;
        overflow: hidden;
        margin: auto;
        .about_header {
          width: 100%;
          margin-bottom: 20px;
          padding-top: 150px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: flex-end;
          .about_router {
            padding-bottom: 15px;
            display: flex;
            div {
              width: 60px;
              height: 42px;
              background: #263551;
              color: #fff;
              text-align: center;
              font-size: 16px;
              line-height: 42px;
              cursor: pointer;
              margin-left: 5px;
            }
            .active {
              background-color: #334871;
            }
          }
        }
        .about_content {
          width: 100 * 3%;
          height: auto;
          display: flex;
          position: relative;
          overflow: hidden;
          transition: all 1s;
          .abuot_introduction {
            width: 100%;
            height: 100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            .abuot_img {
              img {
                width: 100%;
                height: auto;
              }
            }

            .introduction_content {
              width: 60%;
              height: 100%;
              .introduction_img {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-around;
                padding: 15px 0;
              }
              p {
                color: #fff;
                font-size: 18px;
                line-height: 26px;
              }
            }
          }
          .about-swiper {
            display: inline-block;
            width: 100%;
            height: 280px;
          }
          .activity-swiper {
            display: inline-block;
            width: 100%;
            height: 280px;
          }
        }
      }
    }
    .customer_box {
      width: 100vw;
      height: 700px;
      background: url("../assets/image/bg5.jpg") no-repeat;
      .customer {
        width: 80%;
        margin: auto;
        padding-top: 150px;
        .customer_title {
          color: white;
          text-align: center;
          border-bottom: 3px solid #0ddfba;
          padding-bottom: 15px;
          .title_en {
            font-family: "Agency FB";
            font-size: 30px;
            font-weight: bolder;
            text-transform: uppercase;
          }
          .title_name {
            font-family: "幼圆";
            font-size: 20px;
          }
        }
        .customer_content {
          width: 90%;
          height: auto;
          margin: auto;
          background: rgba(255, 255, 255, 0.068);
          padding: 20px 0 0 20px;
          display: flex;
          flex-flow: row wrap;
          justify-content: flex-start;
          .customer_label {
            position: relative;
            width: 21%;
            height: auto;
            margin-right: 2%;
            margin-bottom: 2%;
            padding: 5px;
            img {
              width: 100%;
            }
            .wk_case_xian {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              span {
                position: absolute;
                background: #0ddfba;
                -webkit-transition: all 0.3s
                  cubic-bezier(0.25, 0.46, 0.45, 0.94);
                -moz-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
                -o-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
                transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
              }
              .wk_span_1 {
                top: 0;
                left: 0;
                width: 0;
                height: 2px;
              }
              .wk_span_2 {
                top: 0;
                right: 0;
                width: 2px;
                height: 0;
              }
              .wk_span_3 {
                right: 0;
                bottom: 0;
                width: 0;
                height: 2px;
              }
              .wk_span_4 {
                bottom: 0;
                left: 0;
                width: 2px;
                height: 0;
              }
            }
            &:hover {
              .wk_case_ico {
                opacity: 1;
                -webkit-opacity: 1;
                -moz-opacity: 1;
              }
              .wk_span_1,
              .wk_span_3 {
                width: 100%;
              }
              .wk_span_2,
              .wk_span_4 {
                height: 100%;
              }
            }
          }
          &::after {
            content: "";
            flex-grow: 99;
          }
        }
      }
    }
    .contact_box {
      width: 100vw;
      height: auto;
      background: url("../assets/image/bg6.jpg") no-repeat;
      background-size: 100% 100%;
      padding: 150px 0;
      .contact {
        width: 80%;
        height: auto;
        margin: auto;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        .contact_img {
          width: 100%;
          text-align: center;
          margin-bottom: 20px;
          img {
            width: 70%;
          }
        }
        .contact_content {
          width: 100%;
          ul {
            li {
              height: 60px;
              color: #ccc;
              font-size: 18px;
              display: flex;
              flex-flow: row nowrap;
              align-items: center;
              cursor: pointer;
              .img {
                width: 44px;
                height: 44px;
                margin-right: 15px;
                transition: all 0.2s;
                &.mailbox {
                  background: url("../assets/image/cont_c.png") no-repeat;
                }
                &.address {
                  background: url("../assets/image/cont_d.png") no-repeat;
                }
              }
              span {
                width: 75%;
              }
              &:hover {
                .img {
                  &.mailbox {
                    background: url("../assets/image/cont_c.png") no-repeat 0 -44px;
                  }
                  &.address {
                    background: url("../assets/image/cont_d.png") no-repeat 0 -44px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 850px) {
  #home {
    padding-top: 60px;
    height: auto;
    .public_title {
      color: white;
      text-align: left;
      padding-bottom: 15px;
      .title_en {
        font-family: "Agency FB";
        font-size: 40px;
        font-weight: bolder;
        text-transform: uppercase;
      }
      .title_name {
        font-family: "幼圆";
        font-size: 20px;
      }
      .title_introduction {
        font-family: "幼圆";
        font-size: 15px;
      }
    }
    .qualifications {
      width: 100vw;
      height: auto;
      background: url("../assets/image/bg2.jpg") no-repeat;
      background-size: 100% 100%;
      padding: 100px 0;
      .qualifications_box {
        width: 90vw;
        height: auto;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        align-items: center;
        margin: 0 auto;
        .qualifications_title {
          position: relative;
          width: 20%;
          height: auto;
          .bg {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            height: 0;
            transition: all 0.5s;
            display: none;
          }
          .qualificationsbox {
            height: auto;
            width: 100%;
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            align-items: center;
            position: static;
            top: 0;
            img {
              width: 80%;
            }
            .qualifications_text {
              width: 100%;
              color: white;
              height: auto;
              line-height: 20px;
              text-align: center;
              font-weight: 400;
              font-size: 15px;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
            }
          }
          &:nth-child(1) {
            .bg {
              background-color: #ff00dd;
            }
          }
          &:nth-child(2) {
            .bg {
              background-color: #1599a5;
            }
          }
          &:nth-child(3) {
            .bg {
              background-color: #5e904d;
            }
          }
          &:nth-child(4) {
            .bg {
              background-color: #5d4378;
            }
          }
          &:nth-child(5) {
            .bg {
              background-color: #ff5e00;
            }
          }
          &:hover {
            .bg {
              height: 100%;
            }
          }
        }
      }
    }
    .case {
      width: 100vw;
      height: auto;
      background: url("../assets/image/bg3.jpg") no-repeat;
      background-size: 100% 100%;
      .case_box {
        width: 80%;
        margin: 0 auto;
        padding: 50px 0;
        .case_title {
          margin-bottom: 20px;
        }
      }
    }
    .about {
      width: 100vw;
      height: 850px;
      background: url("../assets/image/bg4.jpg") no-repeat;
      background-size: 100% 100%;
      .about_box {
        width: 90%;
        height: 100%;
        overflow: hidden;
        margin: auto;
        .about_header {
          width: 100%;
          margin-bottom: 20px;
          padding-top: 150px;
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          align-items: flex-end;
          .about_router {
            padding-bottom: 15px;
            display: flex;
            div {
              width: 60px;
              height: 42px;
              background: #263551;
              color: #fff;
              text-align: center;
              font-size: 16px;
              line-height: 42px;
              cursor: pointer;
              margin-left: 5px;
            }
            .active {
              background-color: #334871;
            }
          }
        }
        .about_content {
          width: 100 * 3%;
          height: auto;
          display: flex;
          position: relative;
          overflow: hidden;
          transition: all 1s;
          .abuot_introduction {
            width: 100%;
            height: 100%;
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            align-items: center;
            .abuot_img {
              width: 100%;
              img {
                width: 300px;
              }
            }

            .introduction_content {
              width: 100%;
              height: 100%;
              .introduction_img {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-around;
                padding: 15px 0;
                img {
                  width: 80px;
                }
              }
              p {
                color: #fff;
                font-size: 18px;
                line-height: 26px;
              }
            }
          }
          .about-swiper {
            display: inline-block;
            width: 100%;
            height: 280px;
          }
          .activity-swiper {
            display: inline-block;
            width: 100%;
            height: 280px;
          }
        }
      }
    }
    .customer_box {
      width: 100vw;
      height: auto;
      background: url("../assets/image/bg5.jpg") no-repeat;
      .customer {
        width: 80%;
        margin: auto;
        padding: 100px 0;
        .customer_title {
          color: white;
          text-align: center;
          border-bottom: 3px solid #0ddfba;
          padding-bottom: 15px;
          .title_en {
            font-family: "Agency FB";
            font-size: 30px;
            font-weight: bolder;
            text-transform: uppercase;
          }
          .title_name {
            font-family: "幼圆";
            font-size: 20px;
          }
        }
        .customer_content {
          width: 90%;
          height: auto;
          margin: auto;
          background: rgba(255, 255, 255, 0.068);
          padding: 20px 0 0 20px;
          display: flex;
          flex-flow: row wrap;
          justify-content: flex-start;
          .customer_label {
            position: relative;
            width: 44%;
            height: auto;
            margin-right: 2%;
            margin-bottom: 2%;
            padding: 5px;
            img {
              width: 100%;
            }
            .wk_case_xian {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: none;
              span {
                position: absolute;
                background: #0ddfba;
                -webkit-transition: all 0.3s
                  cubic-bezier(0.25, 0.46, 0.45, 0.94);
                -moz-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
                -o-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
                transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
              }
              .wk_span_1 {
                top: 0;
                left: 0;
                width: 0;
                height: 2px;
              }
              .wk_span_2 {
                top: 0;
                right: 0;
                width: 2px;
                height: 0;
              }
              .wk_span_3 {
                right: 0;
                bottom: 0;
                width: 0;
                height: 2px;
              }
              .wk_span_4 {
                bottom: 0;
                left: 0;
                width: 2px;
                height: 0;
              }
            }
            &:hover {
              .wk_case_ico {
                opacity: 1;
                -webkit-opacity: 1;
                -moz-opacity: 1;
              }
              .wk_span_1,
              .wk_span_3 {
                width: 100%;
              }
              .wk_span_2,
              .wk_span_4 {
                height: 100%;
              }
            }
          }
          &::after {
            content: "";
            flex-grow: 99;
          }
        }
      }
    }
    .contact_box {
      width: 100vw;
      height: auto;
      background: url("../assets/image/bg6.jpg") no-repeat;
      background-size: 100% 100%;
      padding: 150px 0;
      .contact {
        width: 80%;
        height: auto;
        margin: auto;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-around;
        .contact_img {
          width: 100%;
          text-align: center;
          margin-bottom: 20px;
          img {
            width: 70%;
          }
        }
        .contact_content {
          width: 100%;
          ul {
            li {
              height: 60px;
              color: #ccc;
              font-size: 18px;
              display: flex;
              flex-flow: row nowrap;
              align-items: center;
              cursor: pointer;
              .img {
                width: 44px;
                height: 44px;
                margin-right: 15px;
                transition: all 0.2s;
                &.mailbox {
                  background: url("../assets/image/cont_c.png") no-repeat;
                }
                &.address {
                  background: url("../assets/image/cont_d.png") no-repeat;
                }
              }
              span {
                width: 75%;
              }
              &:hover {
                .img {
                  &.mailbox {
                    background: url("../assets/image/cont_c.png") no-repeat 0 -44px;
                  }
                  &.address {
                    background: url("../assets/image/cont_d.png") no-repeat 0 -44px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 425px) {
  #home {
    padding-top: 60px;
    height: auto;
    .public_title {
      color: white;
      text-align: left;
      padding-bottom: 15px;
      .title_en {
        font-family: "Agency FB";
        font-size: 40px;
        font-weight: bolder;
        text-transform: uppercase;
      }
      .title_name {
        font-family: "幼圆";
        font-size: 20px;
      }
      .title_introduction {
        font-family: "幼圆";
        font-size: 15px;
      }
    }
    .qualifications {
      width: 100vw;
      height: auto;
      padding: 50px 0;
      background: url("../assets/image/bg2.jpg") no-repeat;
      background-size: 100% 100%;
      .qualifications_box {
        width: 90vw;
        height: auto;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-content: center;
        margin: 0 auto;
        .qualifications_title {
          position: relative;
          width: 50%;
          height: auto;
          margin: 10px 0;
          .bg {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            height: 0;
            transition: all 0.5s;
            display: none;
          }
          .qualificationsbox {
            height: auto;
            width: 100%;
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            align-items: center;
            position: static;
            top: 0;
            img {
              width: 80%;
            }
            .qualifications_text {
              width: 100%;
              color: white;
              height: auto;
              line-height: 20px;
              text-align: center;
              font-weight: 400;
              font-size: 15px;
            }
          }
          &:nth-child(1) {
            .bg {
              background-color: #ff00dd;
            }
          }
          &:nth-child(2) {
            .bg {
              background-color: #1599a5;
            }
          }
          &:nth-child(3) {
            .bg {
              background-color: #5e904d;
            }
          }
          &:nth-child(4) {
            .bg {
              background-color: #5d4378;
            }
          }
          &:nth-child(5) {
            .bg {
              background-color: #ff5e00;
            }
          }
          &:hover {
            .bg {
              height: 100%;
            }
          }
        }
      }
    }
    .case {
      width: 100vw;
      height: auto;
      background: url("../assets/image/bg3.jpg") no-repeat;
      background-size: 100% 100%;
      .case_box {
        width: 80%;
        margin: 0 auto;
        padding: 50px 0;
        .case_title {
          margin-bottom: 20px;
        }
      }
    }
    .about {
      width: 100vw;
      height: 850px;
      background: url("../assets/image/bg4.jpg") no-repeat;
      background-size: 100% 100%;
      .about_box {
        width: 90%;
        height: 100%;
        overflow: hidden;
        margin: auto;
        .about_header {
          width: 100%;
          margin-bottom: 20px;
          padding-top: 150px;
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          align-items: flex-end;
          .about_router {
            padding-bottom: 15px;
            display: flex;
            div {
              width: 60px;
              height: 42px;
              background: #263551;
              color: #fff;
              text-align: center;
              font-size: 16px;
              line-height: 42px;
              cursor: pointer;
              margin-left: 5px;
            }
            .active {
              background-color: #334871;
            }
          }
        }
        .about_content {
          width: 100 * 3%;
          height: auto;
          display: flex;
          position: relative;
          overflow: hidden;
          transition: all 1s;
          .abuot_introduction {
            width: 100%;
            height: 100%;
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            align-items: center;
            .abuot_img {
              width: 100%;
              img {
                width: 150px;
              }
            }

            .introduction_content {
              width: 100%;
              height: 100%;
              .introduction_img {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-around;
                padding: 15px 0;
                img {
                  width: 80px;
                }
              }
              p {
                color: #fff;
                font-size: 18px;
                line-height: 26px;
              }
            }
          }
          .about-swiper {
            display: inline-block;
            width: 100%;
            height: 100%;
          }
          .activity-swiper {
            display: inline-block;
            width: 100%;
            height: 110%;
          }
        }
      }
    }
    .customer_box {
      width: 100vw;
      height: 700px;
      background: url("../assets/image/bg5.jpg") no-repeat;
      .customer {
        width: 80%;
        margin: auto;
        padding-top: 150px;
        .customer_title {
          color: white;
          text-align: center;
          border-bottom: 3px solid #0ddfba;
          padding-bottom: 15px;
          .title_en {
            font-family: "Agency FB";
            font-size: 30px;
            font-weight: bolder;
            text-transform: uppercase;
          }
          .title_name {
            font-family: "幼圆";
            font-size: 20px;
          }
        }
        .customer_content {
          width: 90%;
          height: auto;
          margin: auto;
          background: rgba(255, 255, 255, 0.068);
          padding: 20px 0 0 20px;
          display: flex;
          flex-flow: row wrap;
          justify-content: flex-start;
          .customer_label {
            position: relative;
            width: 44%;
            height: auto;
            margin-right: 2%;
            margin-bottom: 2%;
            padding: 5px;
            img {
              width: 100%;
            }
            .wk_case_xian {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: none;
              span {
                position: absolute;
                background: #0ddfba;
                -webkit-transition: all 0.3s
                  cubic-bezier(0.25, 0.46, 0.45, 0.94);
                -moz-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
                -o-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
                transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
              }
              .wk_span_1 {
                top: 0;
                left: 0;
                width: 0;
                height: 2px;
              }
              .wk_span_2 {
                top: 0;
                right: 0;
                width: 2px;
                height: 0;
              }
              .wk_span_3 {
                right: 0;
                bottom: 0;
                width: 0;
                height: 2px;
              }
              .wk_span_4 {
                bottom: 0;
                left: 0;
                width: 2px;
                height: 0;
              }
            }
            &:hover {
              .wk_case_ico {
                opacity: 1;
                -webkit-opacity: 1;
                -moz-opacity: 1;
              }
              .wk_span_1,
              .wk_span_3 {
                width: 100%;
              }
              .wk_span_2,
              .wk_span_4 {
                height: 100%;
              }
            }
          }
          &::after {
            content: "";
            flex-grow: 99;
          }
        }
      }
    }
    .contact_box {
      width: 100vw;
      height: auto;
      background: url("../assets/image/bg6.jpg") no-repeat;
      background-size: 100% 100%;
      padding: 150px 0;
      .contact {
        width: 80%;
        height: auto;
        margin: auto;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-around;
        .contact_img {
          width: 100%;
          text-align: center;
          margin-bottom: 20px;
          img {
            width: 70%;
          }
        }
        .contact_content {
          width: 100%;
          ul {
            li {
              height: 60px;
              color: #ccc;
              font-size: 18px;
              display: flex;
              flex-flow: row nowrap;
              align-items: center;
              cursor: pointer;
              .img {
                width: 44px;
                height: 44px;
                margin-right: 15px;
                transition: all 0.2s;
                &.mailbox {
                  background: url("../assets/image/cont_c.png") no-repeat;
                }
                &.address {
                  background: url("../assets/image/cont_d.png") no-repeat;
                }
              }
              &:hover {
                .img {
                  &.mailbox {
                    background: url("../assets/image/cont_c.png") no-repeat 0 -44px;
                  }
                  &.address {
                    background: url("../assets/image/cont_d.png") no-repeat 0 -44px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>

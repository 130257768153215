<template>
	<div class="footer_box">
		<div class="footer">
			<div class="footer_title">
				<router-link tag="h4" to="/about">关于我们</router-link>
				<ul>
					<router-link tag="li" v-for="about in aboutData" :key="about.id"
						:to="{ name: 'About', params: { id: about.id } }">
						{{about.name}}
					</router-link>
				</ul>
			</div>
			<div class="footer_title">
				<router-link tag="h4" to="/case">产品案例</router-link>
				<ul>
					<router-link tag="li" v-for="cases in caseData" :key="cases.id"
						:to="{ name: 'Case', params: { id: cases.id } }">
						{{cases.title}}
					</router-link>
				</ul>
			</div>
			<div class="footer_title">
				<h4>服务客户</h4>
				<ul>
					<!-- <router-link
            tag="li"
            v-for="service in serviceData"
            :key="service.id"
            :to="{ name: 'Case', params: { id: service.id } }"
          >
            {{service.title}}
          </router-link> -->
					<li v-for="service in serviceData" :key="service.title">
						<a :href="service.url" target="_blank">
							{{service.title}}
						</a>
					</li>
				</ul>
			</div>
			<div class="footer_title service">
				<h4>服务热线</h4>
				<ul>
					<li>15519158066</li>
				</ul>
				<h4>邮 箱</h4>
				<ul>
					<li>15519158066@wo.cn</li>
				</ul>
			</div>
			<div class="footer_title">
				<img src="../../assets/image/WeChat.png" alt=""><br>
				<span>关注</span><br>
				<span>官方公众号</span>
			</div>
		</div>
		<div class="text">
			Copyright ©贵州智联云弛软件科技有限公司 <br>
			<img src="../../assets/image/gongshang.png" alt="" style="margin-right:2px;width: 13px;height: 12px;">
			<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">贵公网安备52011502002782号</a>
			&nbsp;&nbsp;&nbsp;&nbsp;
			<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">黔ICP备19004188号-3</a>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['displays'],
		data() {
			return {
				aboutData: [{
						name: "公司简介",
						id: "introduction",
					},
					{
						name: "发展历程",
						id: "course",
					},
					{
						name: "公司团队",
						id: "team",
					},
					{
						name: "公司活动",
						id: "activity",
					},
				],
				caseData: [{
						id: "building",
						title: "智慧楼宇",
					},
					{
						id: "carving",
						title: "5G云雕刻",
					},
					{
						id: "party",
						title: "智慧党建",
					},
					{
						id: "fire",
						title: "智慧消防",
					},
					{
						id: "office",
						title: "智慧办公",
					},
					{
						id: "marathon",
						title: "智慧马拉松",
					},
					{
						id: "travel",
						title: "智慧旅游平台",
					},
					{
						id: "community",
						title: "智慧社区",
					},
					{
						id: "exhibition",
						title: "智慧展厅",
					},
					{
						id: "campus",
						title: "智慧校园",
					},
				],
				serviceData: [{
						url: "http://www.10086.cn/index/gd/index_200_200.html",
						title: "中国移动通信",
					},
					{
						url: "https://www.china-tower.com/index",
						title: "中国铁塔",
					},
					{
						url: "http://www.sdlckj.com/",
						title: "力创科技",
					},
					{
						url: "https://www.cloudwalk.com/",
						title: "云从科技",
					},
					{
						url: "https://www.inspur.com/",
						title: "浪潮",
					},
					{
						url: "http://www.hxcy.com.cn/",
						title: "杭州华星创业通信",
					},
				],
			}
		}
	}
</script>

<style lang="scss" scoped>
	.footer_box {
		width: 100vw;
		height: auto;
		background-color: black;
		padding: 20px 0;

		.footer {
			width: 1160px;
			height: auto;
			margin: auto;
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;

			.footer_title {
				text-align: center;

				h4 {
					color: white;
					font-weight: 500;
					height: 45px;
					line-height: 45px;
					cursor: pointer;
					text-align: left;
				}

				ul {
					li {
						color: #b6b3b3;
						text-align: left;
						cursor: pointer;

						&:hover {
							color: white;
						}

						a {
							color: #b6b3b3;
							text-align: left;

							&:hover {
								color: white;
							}
						}
					}
				}

				img {
					margin: 30px 0;
					text-align: center;
				}

				span {
					color: #b6b3b3;
				}
			}
		}

		.text {
			width: 1160px;
			margin: auto;
			border-top: 1px solid #eeeeee;
			color: #b6b3b3;
			text-align: center;
			margin-top: 10px;
			padding-top: 10px;

			a {
				color: #b6b3b3;
			}

			img {
				width: 20px;
				height: 20px;
			}
		}
	}

	@media (max-width: 1160px) {
		.footer_box {
			width: 100vw;
			height: auto;
			background-color: black;
			padding: 20px 0;

			.footer {
				width: 80%;
				height: auto;
				margin: auto;
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;

				.footer_title {
					h4 {
						color: #c9c9c9;
						font-weight: 500;
						height: 45px;
						line-height: 45px;
					}

					ul {
						li {
							color: #b6b3b3;
						}
					}
				}
			}

			.text {
				width: 100%;
				margin: auto;
				border-top: 1px solid #eeeeee;
				color: #b6b3b3;
				text-align: center;
				margin-top: 10px;
				padding-top: 10px;

				a {
					color: #b6b3b3;
				}

				img {
					width: 20px;
					height: 20px;
				}
			}
		}
	}

	@media (max-width: 1024px) {
		.footer_box {
			width: 100vw;
			height: auto;
			background-color: black;
			padding: 20px 0;

			.footer {
				width: 80%;
				height: auto;
				margin: auto;
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;

				.footer_title {
					h4 {
						color: #c9c9c9;
						font-weight: 500;
						height: 45px;
						line-height: 45px;
					}

					ul {
						li {
							color: #b6b3b3;
						}
					}
				}
			}

			.text {
				width: 100%;
				margin: auto;
				border-top: 1px solid #eeeeee;
				color: #b6b3b3;
				text-align: center;
				margin-top: 10px;
				padding-top: 10px;

				a {
					color: #b6b3b3;
				}

				img {
					width: 20px;
					height: 20px;
				}
			}
		}
	}

	@media (max-width: 768px) {
		.footer_box {
			width: 100vw;
			height: auto;
			background-color: black;
			padding: 20px 0;

			.footer {
				width: 80%;
				height: auto;
				margin: auto;
				display: flex;
				flex-flow: column nowrap;
				justify-content: space-between;

				.footer_title {
					text-align: left;

					h4 {
						color: #c9c9c9;
						font-weight: 500;
						height: 45px;
						line-height: 45px;
					}

					ul {
						display: none;

						li {
							color: #b6b3b3;
						}
					}

					img {
						margin: 30px 0;
						text-align: left;
					}

					span {
						display: none;
						color: #b6b3b3;
					}

					&.service {
						ul {
							display: block;
						}
					}
				}
			}

			.text {
				width: 100%;
				margin: auto;
				border-top: 1px solid #eeeeee;
				color: #b6b3b3;
				text-align: center;
				margin-top: 10px;
				padding-top: 10px;

				a {
					color: #b6b3b3;
				}

				img {
					width: 20px;
					height: 20px;
				}
			}
		}
	}

	@media (max-width: 425px) {
		.footer_box {
			width: 100vw;
			height: auto;
			background-color: black;
			padding: 20px 0;

			.footer {
				width: 80%;
				height: auto;
				margin: auto;
				display: flex;
				flex-flow: column nowrap;
				justify-content: space-between;

				.footer_title {
					text-align: left;

					h4 {
						color: #c9c9c9;
						font-weight: 500;
						height: 30px;
						line-height: 30px;
					}

					ul {
						display: none;

						li {
							color: #b6b3b3;
						}
					}

					img {
						margin: 30px 0;
						text-align: left;
					}

					span {
						display: none;
						color: #b6b3b3;
					}

					&.service {
						ul {
							display: block;
						}
					}
				}
			}

			.text {
				width: 100%;
				margin: auto;
				border-top: 1px solid #eeeeee;
				color: #b6b3b3;
				text-align: center;
				margin-top: 10px;
				padding-top: 10px;

				a {
					color: #b6b3b3;
				}

				img {
					width: 20px;
					height: 20px;
				}
			}
		}
	}
</style>

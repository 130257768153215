<template>
  <div class="swiper-container activity-swiper">
    <div class="swiper-wrapper">
      <div
        class="swiper-slide"
        v-for="aboutbox in aboutData"
        :key="aboutbox.url"
      >
        <div class="content_box">
          <div class="content_img">
            <img
              :src="aboutbox.url"
              alt=""
            />
          </div>
          <p>{{aboutbox.text}}</p>
        </div>
      </div>
    </div>
    <div
      class="swiper-pagination swiper-pagination-white"
      id="swiper-spit"
    ></div>
    <!-- <div class="swiper-button-next swiper-button-white"></div>
    <div class="swiper-button-prev swiper-button-white"></div> -->
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
import "swiper/dist/js/swiper.min.js";

export default {
  props: ["screenWidth"],
  data() {
    return {
      aboutData: [

        {
          url: require("../../assets/image/activity3.jpg"),
          text: "公司商标"
        },
        {
          url: require("../../assets/image/activity10.jpg"),
          text: "公司年会"
        },
        {
          url: require("../../assets/image/activity6.jpg"),
          text: "公司年会"
        },
        {
          url: require("../../assets/image/activity11.jpg"),
          text: "公司年会"
        },
        {
          url: require("../../assets/image/activity12.jpg"),
          text: "公司年会"
        },
        {
          url: require("../../assets/image/activity13.jpg"),
          text: "公司年会"
        },
        {
          url: require("../../assets/image/activity8.jpg"),
          text: "公司活动"
        },
        {
          url: require("../../assets/image/activity9.jpg"),
          text: "公司活动"
        },
        {
          url: require("../../assets/image/activity5.jpg"),
          text: "公司活动"
        },
        {
          url: require("../../assets/image/activity14.jpg"),
          text: "公司活动"
        },
        {
          url: require("../../assets/image/activity15.jpg"),
          text: "公司环境"
        },
        {
          url: require("../../assets/image/activity20.jpg"),
          text: "公司环境"
        },
        {
          url: require("../../assets/image/activity21.jpg"),
          text: "公司环境"
        },
      ],
      View: 0,
    };
  },
  watch: {
    screenWidth: {
      handler() {
        this.switch();
      },
      deep: true,
    },
  },
  mounted() {
    this.switch();
  },
  methods: {
    // 大图轮播
    switch() {
      if (this.screenWidth <= 425) {
        this.View = 1;
      } else if (this.screenWidth <= 768) {
        this.View = 2;
      } else {
        this.View = 3;
      }
      setTimeout(() => {
        const swiper = new Swiper(".activity-swiper", {
          slidesPerView: this.View,
          slidesPerColumn: 1,
          spaceBetween: 20,
        });
        swiper.slideNext();
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-container {
  .swiper-wrapper {
    height: auto;
    .swiper-slide {
      position: relative;
      height: auto;
      cursor: pointer;
      padding-top: 3%;
      .content_box {
        width: 80%;
        margin: auto;
        .content_img {
          width: 100%;
          height: 300px;
          img {
            width: 100%;
            height: 100%;
          }
        }

        P {
          line-height: 40px;
          color: #fff;
          text-align: center;
        }
      }
    }
  }
}
@media (max-width: 1160px) {
  .swiper-slide .title {
    top: 17%;
  }
  .swiper-slide .title h1 {
    color: white;
    font-size: 2em;
    font-style: normal;
    font-family: inherit;
    font-weight: 500;
  }
  .swiper-slide .title p {
    color: white;
    line-height: 180%;
    font-size: 1.3em;
    margin-top: 40px;
    font-style: normal;
    font-family: inherit;
    font-weight: 300;
  }
}
@media (max-width: 1024px) {
  .swiper-container {
    .swiper-wrapper {
      padding-top: 30px;
      height: auto;
      .swiper-slide {
        height: auto;
        cursor: pointer;
        .content_box {
          width: 79%;
          margin: auto;
          .content_img {
            width: 100%;
            max-height: 180px;
            img {
              width: 100%;
              height: 100%;
            }
          }

          P {
            line-height: 40px;
            color: #fff;
            text-align: center;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .swiper-container {
    .swiper-wrapper {
      .swiper-slide {
        height: auto;
        cursor: pointer;
        padding-bottom: 10px;
        .content_box {
          width: 79%;
          max-height: 200px;
          margin-top: 20px;
        }
      }
    }
  }
}
@media (max-width: 425px) {
  .swiper-container {
    .swiper-wrapper {
      .swiper-slide {
        height: auto;
        cursor: pointer;
        padding-bottom: 10px;
        .content_box {
          width: 79%;
          max-height: 220px;
          margin-top: 20px;
          .content_img {
            width: 100%;
            max-height: 200px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

.hide {
  opacity: 0;
}
</style>

<template>
  <div class="swiper-container case-swiper">
    <div class="swiper-wrapper">
      <div
        class="swiper-slide"
        v-for="cases in caseData"
        :key="cases.title"
        :title="cases.title"
      >
        <router-link
          tag="div"
          class="box"
          :to="{ name: 'Case', params: { id: cases.id } }"
        >
          <img :src="cases.url" alt="" />
          <div class="wk_case_ico"></div>
          <div class="wk_case_xian">
            <span class="wk_span_1"></span>
            <span class="wk_span_2"></span>
            <span class="wk_span_3"></span>
            <span class="wk_span_4"></span>
          </div>
          <div class="wk_name">
            {{ cases.title }}
          </div>
        </router-link>
      </div>
    </div>
    <div class="swiper-button-next swiper-button-white" v-if="displays"></div>
    <div class="swiper-button-prev swiper-button-white" v-if="displays"></div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
import "swiper/dist/js/swiper.min.js";

export default {
  props: ["displays", "screenWidth"],
  data() {
    return {
      caseData: [
        {
          id: "building",
          title: "智慧楼宇",
          url: require("../../assets/image/case1.png"),
        },
        {
          id: "community",
          title: "智慧社区",
          url: require("../../assets/image/case12.png"),
        },
        {
          id: "carving",
          title: "5G云雕刻",
          url: require("../../assets/image/case2.jpg"),
        },
        {
          id: "party",
          title: "智慧党建",
          url: require("../../assets/image/case3.png"),
        },
        {
          id: "fire",
          title: "智慧消防",
          url: require("../../assets/image/case4.png"),
        },
        {
          id: "office",
          title: "智慧办公",
          url: require("../../assets/image/case5.png"),
        },
        {
          id: "marathon",
          title: "智慧马拉松",
          url: require("../../assets/image/case6.jpg"),
        },
        {
          id: "travel",
          title: "智慧旅游平台",
          url: require("../../assets/image/case7.png"),
        },
        {
          id: "park",
          title: "智慧园区",
          url: require("../../assets/image/SmartCommunity.png"),
        },
        {
          id: "exhibition",
          title: "智慧展厅",
          url: require("../../assets/image/case10.png"),
        },
        {
          id: "campus",
          title: "智慧校园",
          url: require("../../assets/image/case11.png"),
        },
      ],
      View: 4,
    };
  },
  watch: {
    screenWidth: {
      handler() {
        this.switch();
      },
      deep: true,
    },
  },
  mounted() {
    this.switch();
  },
  methods: {
    // 大图轮播
    switch() {
      if (this.screenWidth <= 768) {
        this.View = 2;
      } else {
        this.View = 4;
      }
      setTimeout(() => {
        const swiper = new Swiper(".case-swiper", {
          slidesPerView: this.View,
          slidesPerColumn: 2,
          spaceBetween: 20,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
        swiper.slideNext();
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-container {
  height: auto;
  width: 100%;
  .swiper-wrapper {
    height: auto;
    .swiper-slide {
      height: auto;
      .box {
        position: relative;
        width: 100%;
        height: 100%;
        cursor: pointer;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
        .wk_case_ico {
          width: 100%;
          height: 100%;
          background: url("../../assets/image/case_ico.png") no-repeat center;
          background-size: 100% 100%;
          position: absolute;
          top: 0;
          opacity: 0;
          transition: all 0.3s;
        }
        .wk_case_xian {
          position: absolute;
          top: 5%;
          left: 10%;
          width: 80%;
          height: 90%;
          span {
            position: absolute;
            background: #0ddfba;
            -webkit-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            -moz-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            -o-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
          }
          .wk_span_1 {
            top: 0;
            left: 0;
            width: 0;
            height: 2px;
          }
          .wk_span_2 {
            top: 0;
            right: 0;
            width: 2px;
            height: 0;
          }
          .wk_span_3 {
            right: 0;
            bottom: 0;
            width: 0;
            height: 2px;
          }
          .wk_span_4 {
            bottom: 0;
            left: 0;
            width: 2px;
            height: 0;
          }
        }
        .wk_name {
          width: 100%;
          height: 0px;
          line-height: 40px;
          text-align: center;
          color: white;
          background-color: #12253e88;
          position: absolute;
          bottom: 0;
          transition: all 0.3s;
        }

        &:hover {
          .wk_case_ico {
            opacity: 1;
            -webkit-opacity: 1;
            -moz-opacity: 1;
          }
          .wk_span_1,
          .wk_span_3 {
            width: 100%;
          }
          .wk_span_2,
          .wk_span_4 {
            height: 100%;
          }
          .wk_name {
            height: 40px;
          }
        }
      }
    }
  }
}

@media (max-width: 1160px) {
  .swiper-slide .title {
    top: 17%;
  }
  .swiper-slide .title h1 {
    color: white;
    font-size: 2em;
    font-style: normal;
    font-family: inherit;
    font-weight: 500;
  }
  .swiper-slide .title p {
    color: white;
    line-height: 180%;
    font-size: 1.3em;
    margin-top: 40px;
    font-style: normal;
    font-family: inherit;
    font-weight: 300;
  }
}
@media (max-width: 1024px) {
  .swiper-container {
    height: auto;
    width: 100%;
    .swiper-wrapper .swiper-slide {
      width: 100%;
      height: auto;
      .box {
        position: relative;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
        .title {
          margin: auto;
          color: #00fff2;
          position: absolute;
          top: 0;
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          align-items: center;
          z-index: 10;
          .btn img {
            margin-left: 13px;
            width: 20px;
            height: 4px;
          }
        }
        .schedule {
          width: 75%;
          height: 2px;
          /* border: 1px solid black; */
          background-color: #5a5755;
          position: absolute;
          bottom: 5%;
          left: 12%;
          z-index: 10;
          /* transform: translate(0, 0) scale(1);
  transition: transform 9s linear 0s; */
          p {
            color: white;
            position: absolute;
            top: -35px;
          }
          span {
            width: 4px;
            height: 4px;
            color: white;
            position: absolute;
            right: 0;
          }
        }
      }
    }

    .ani-slide {
      img {
        transform: translate(-1%, 0px) scale(1.1);
      }
      .title {
        h3 {
          transform: translateY(0);
          opacity: 1;
        }
        h2 {
          transform: translateY(0);
          opacity: 1;
        }
        .btn {
          transform: translateY(0);
          opacity: 1;
        }
      }
      .schedule em {
        display: block;
        height: 3px;
        width: 100%;
      }
    }
  }

  .swiper-slide .title h3 {
    font-size: 25px;
    margin: 20px 0;
    opacity: 0;
    transform: translateY(100px);
    transition: all 1s;
  }
  .swiper-slide .title h2 {
    font-size: 36px;
    opacity: 0;
    transform: translateY(100px);
    transition: all 2s;
  }
}
@media (max-width: 768px) {
  .swiper-container {
    height: auto;
    width: 100%;
    .swiper-wrapper {
      height: auto;
      .swiper-slide {
        width: 275px;
        height: auto;
        .box {
          position: relative;
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
          .wk_case_ico {
            width: 100%;
            height: 100%;
            background: url("../../assets/image/case_ico.png") no-repeat center;
            background-size: 100% 100%;
            position: absolute;
            top: 0;
            opacity: 0;
            transition: all 0.3s;
            display: none;
          }
          .wk_case_xian {
            position: absolute;
            top: 5%;
            left: 10%;
            width: 80%;
            height: 90%;
            display: none;
            span {
              position: absolute;
              background: #0ddfba;
              -webkit-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
              -moz-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
              -o-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
              transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            }
            .wk_span_1 {
              top: 0;
              left: 0;
              width: 0;
              height: 2px;
            }
            .wk_span_2 {
              top: 0;
              right: 0;
              width: 2px;
              height: 0;
            }
            .wk_span_3 {
              right: 0;
              bottom: 0;
              width: 0;
              height: 2px;
            }
            .wk_span_4 {
              bottom: 0;
              left: 0;
              width: 2px;
              height: 0;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 425px) {
  .swiper-container {
    height: auto;
    width: 100%;
    .swiper-wrapper {
      height: auto;
      .swiper-slide {
        width: 275px;
        height: auto;
        .box {
          position: relative;
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
          .wk_case_ico {
            width: 100%;
            height: 100%;
            background: url("../../assets/image/case_ico.png") no-repeat center;
            background-size: 100% 100%;
            position: absolute;
            top: 0;
            opacity: 0;
            transition: all 0.3s;
            display: none;
          }
          .wk_case_xian {
            position: absolute;
            top: 5%;
            left: 10%;
            width: 80%;
            height: 90%;
            display: none;
            span {
              position: absolute;
              background: #0ddfba;
              -webkit-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
              -moz-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
              -o-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
              transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            }
            .wk_span_1 {
              top: 0;
              left: 0;
              width: 0;
              height: 2px;
            }
            .wk_span_2 {
              top: 0;
              right: 0;
              width: 2px;
              height: 0;
            }
            .wk_span_3 {
              right: 0;
              bottom: 0;
              width: 0;
              height: 2px;
            }
            .wk_span_4 {
              bottom: 0;
              left: 0;
              width: 2px;
              height: 0;
            }
          }
          .wk_name {
            background-color: rgba(18, 37, 62, 0.8);
            height: 40px;
          }
        }
      }
    }
  }
}

.hide {
  opacity: 0;
}
</style>

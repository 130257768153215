<template>
  <div class="swiper-container  home-swiper">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <img
          src="../../assets/image/Rotation1.jpg"
          alt=""
        >
      </div>
      <div class="swiper-slide">
        <img
          src="../../assets/image/Rotation2.jpg"
          alt=""
        >
        <div
          class="title"
          v-if="displays"
        >
          <h2>云上贵州</h2>
          <p>贵州智联云弛软件科技有限公司是入驻国家高新开发区━贵安新区的高新技术和软件企业，办公室地址位于贵州省贵安新区贵安电商生态城内，于2017年05月18日在贵州贵安新区行政审批局注册成立，注册资本为1000 万人民币元。</p>
          <div class="bg"></div>
        </div>
      </div>
      <div class="swiper-slide">
        <img
          src="../../assets/image/Rotation3.jpg"
          alt=""
        >
        <div
          class="title"
          v-if="displays"
        >
          <h2>张弛有度</h2>
          <p>公司坚定不移地立足科技创新，潜心产品技术研发，注重产品品质和诚信服务，汇集了一批专业技术领域的优秀人才，组成了以中青年科技精英为骨干的高素质科研队伍，在不断的产品研发实践中积累了丰富的产品设计经验和精深的理论知识。我们还有好的产品和专业的销售和技术团队。</p>
          <div class="bg"></div>
        </div>
      </div>
      <div class="swiper-slide">
        <img
          src="../../assets/image/Rotation4.jpg"
          alt=""
        >
        <div
          class="title"
          v-if="displays"
        >
          <h2>人工智能</h2>
          <p>本公司主要经营范围:1、计算机领域:技术咨询、服务、转让，游戏开发，自动化控制系统研发，软件开发，计算机信息系统集成服务;2、互联网领域:互联网信息服务、计算机网络工程设计、开发与施工，网络技术开发、技术服务和技术支持;3、物联网领域:网络设备安装与维护，智能网络控制系统设备的设计及安装;网络系统工程设计与安装。</p>
          <div class="bg"></div>
        </div>
      </div>
      <div class="swiper-slide">
        <img
          src="../../assets/image/Rotation5.jpg"
          alt=""
        >
        <div
          class="title"
          v-if="displays"
        >
          <h2>万物互联</h2>
          <p>深耕信息传输、软件和信息技术服务业、大数据、人工智能及物联网科技研发。本公司在贵州省成立之后，发展迅速，本着雄厚的技术实力，不断积累创新先进技术。在公司发展壮大的2年里，我们始终为客户提供好的产品和技术支持、健全的售后服务。</p>
          <div class="bg"></div>
        </div>
      </div>
    </div>
    <div
      class="swiper-pagination swiper-pagination-white"
      id="swiper-spit"
    ></div>

    <div
      class="swiper-button-next swiper-button-white"
      v-if="displays"
    ></div>
    <div
      class="swiper-button-prev swiper-button-white"
      v-if="displays"
    ></div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/dist/css/swiper.min.css';
import 'swiper/dist/js/swiper.min.js';

export default {
  props: ['displays'],
  data() {
    return {};
  },
  mounted() {
    this.switch();
  },
  methods: {
    // 大图轮播
    switch() {
      const swiper = new Swiper('.home-swiper', {
        loop: true,
        followFinger: false,
        observer: true,
        observeParents: true,
        speed: 800,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
        },
        _on: {
          init() {
            const slide = this.slides.eq(0);
            slide.addClass('ani-slide');
          },
          transitionStart() {
            for (let i = 0; i < this.slides.length; i++) {
              const slide = this.slides.eq(i);
              slide.removeClass('ani-slide');
            }
          },
          transitionEnd() {
            const slide = this.slides.eq(this.activeIndex);
            slide.addClass('ani-slide');
          },
        },
        get on() {
          return this._on;
        },
        set on(value) {
          this._on = value;
        },
      });
      // 鼠标移出隐藏按钮，移入显示按钮
      swiper.el.onmouseover = function () {
        swiper.navigation.$nextEl.removeClass("hide");
        swiper.navigation.$prevEl.removeClass("hide");
      };
      swiper.el.onmouseout = function () {
        swiper.navigation.$nextEl.addClass("hide");
        swiper.navigation.$prevEl.addClass("hide");
      };
      swiper.slideNext();
    },
    // jump() {
    //   this.$router.push('/about');
    // },
  },
};
</script>

<style lang="scss" scoped>
.swiper-container {
  height: auto;
  width: 100%;
  .swiper-wrapper {
    height: auto;
    .swiper-slide {
      position: relative;
      width: 100%;
      height: auto;
      img {
        width: 100%;
        height: 100%;
      }
      .title {
        width: 85vw;
        height: 100vw;
        margin: auto;
        color: white;
        position: absolute;
        left: 5vw;
        padding-left: 5vw;
        top: 70%;
        z-index: 10;
      }
    }
  }

  .ani-slide {
    .title {
      h2 {
        transform: translateY(0);
        opacity: 1;
      }
      p {
        transform: translateY(0);
        opacity: 1;
      }
      .bg {
        width: 100%;
      }
    }
  }
}

.swiper-slide .title h2 {
  position: absolute;
  font-size: 50px;
  font-style: normal;
  font-family: inherit;
  font-weight: 500;
  opacity: 0;
  transform: translateX(100px);
  transition: all 0.5s;
  z-index: 9;
}
.swiper-slide .title p {
  position: absolute;
  font-size: 22px;
  font-weight: 300;
  margin: 20px 0;
  width: 90%;
  top: 70px;
  opacity: 0;
  transform: translateX(100px);
  transition: all 3s;
  z-index: 9;
}
.swiper-slide .title .bg {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 0%;
  height: 100%;
  transition: all 3s;
  background-color: #12253e88;
  z-index: 1;
}
@media (max-width: 1350px) {
  .swiper-container {
    height: auto;
    width: 100%;
    .swiper-wrapper {
      height: auto;
      .swiper-slide {
        position: relative;
        width: 100%;
        height: auto;
        img {
          width: 100%;
          height: 100%;
        }
        .title {
          width: 85vw;
          height: 100vw;
          margin: auto;
          color: white;
          position: absolute;
          left: 5vw;
          padding-left: 5vw;
          top: 55%;
          z-index: 10;
        }
      }
    }

    .ani-slide {
      .title {
        h2 {
          transform: translateY(0);
          opacity: 1;
        }
        p {
          transform: translateY(0);
          opacity: 1;
        }
        .bg {
          width: 100%;
        }
      }
    }
  }
  .swiper-slide .title h1 {
    color: white;
    font-size: 2em;
    font-style: normal;
    font-family: inherit;
    font-weight: 500;
  }
  .swiper-slide .title p {
    color: white;
    line-height: 180%;
    font-size: 1.2em;
    margin-top: 10px;
    font-style: normal;
    font-family: inherit;
    font-weight: 300;
  }
}
@media (max-width: 1024px) {
  .swiper-container {
    height: auto;
    width: 100%;
    .swiper-wrapper .swiper-slide {
      position: relative;
      width: 100%;
      height: auto;
      img {
        width: 100%;
        height: auto;
      }
      .title {
        margin: auto;
        color: #00fff2;
        position: absolute;
        top: 0;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        z-index: 10;
        .btn img {
          margin-left: 13px;
          width: 20px;
          height: 4px;
        }
      }
      .schedule {
        width: 75%;
        height: 2px;
        /* border: 1px solid black; */
        background-color: #5a5755;
        position: absolute;
        bottom: 5%;
        left: 12%;
        z-index: 10;
        /* transform: translate(0, 0) scale(1);
  transition: transform 9s linear 0s; */
        p {
          color: white;
          position: absolute;
          top: -35px;
        }
        span {
          width: 4px;
          height: 4px;
          color: white;
          position: absolute;
          right: 0;
        }
      }
    }

    .ani-slide {
      img {
        transform: translate(-1%, 0px) scale(1.1);
      }
      .title {
        h3 {
          transform: translateY(0);
          opacity: 1;
        }
        h2 {
          transform: translateY(0);
          opacity: 1;
        }
        .btn {
          transform: translateY(0);
          opacity: 1;
        }
      }
      .schedule em {
        display: block;
        height: 3px;
        width: 100%;
      }
    }
  }

  .swiper-slide .title h3 {
    font-size: 25px;
    margin: 20px 0;
    opacity: 0;
    transform: translateY(100px);
    transition: all 1s;
  }
  .swiper-slide .title h2 {
    font-size: 36px;
    opacity: 0;
    transform: translateY(100px);
    transition: all 2s;
  }
}
@media (max-width: 768px) {
  .swiper-container {
    height: auto;
    width: 100%;
    .swiper-wrapper {
      height: auto;
      .swiper-slide {
        position: relative;
        width: 100% !important;
        height: auto;
        img {
          width: 100% !important;
          height: 100%;
        }
        .title {
          margin: auto;
          color: white;
          position: absolute;
          padding-left: 200px;
          top: 35%;
          z-index: 10;
        }
      }
    }

    .ani-slide {
      .title {
        h2 {
          transform: translateY(0);
          opacity: 1;
        }
        p {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }
  // .swiper-slide .title {
  //   width: 80vw;
  //   height: auto;
  //   position: absolute;
  // }
  .swiper-slide .title h3 {
    font-size: 22px;
    margin: 20px 0;
    opacity: 0;
    transform: translateY(100px);
    transition: all 1s;
  }
  .swiper-slide .title h1 {
    color: white;
    font-size: 2em;
    font-style: normal;
    font-family: inherit;
    font-weight: 500;
  }
  .swiper-slide .title h2 {
    font-size: 30px;
    opacity: 0;
    transform: translateY(100px);
    transition: all 2s;
  }

  .swiper-slide .title .btn {
    color: #e6dfd9;
    font-size: 14px;
    width: auto;
    padding: 15px 25px;
    font-weight: 400;
    background: #00fff249;
    border: 1px solid #00fff2;
    margin-top: 50px;
    opacity: 0;
    transform: translateY(100px);
    text-transform: uppercase;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    transition: all 3s;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
  .swiper-slide .title p {
    color: white;
    line-height: 180%;
    font-size: 1.3em;
    margin-top: 20px;
    font-style: normal;
    font-family: inherit;
    font-weight: 300;
  }
}
@media (max-width: 425px) {
  .swiper-container {
    height: auto;
    width: 100%;
    .swiper-wrapper {
      height: auto;
      .swiper-slide {
        position: relative;
        width: 100%;
        height: auto;
        img {
          width: 100%;
          height: 100%;
        }
        .title {
          margin: auto;
          color: white;
          position: absolute;
          padding-left: 0;
          top: 15%;
          z-index: 10;
        }
      }
    }

    .ani-slide {
      .title {
        h2 {
          transform: translateY(0);
          opacity: 1;
        }
        p {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }

  .swiper-slide .title h2 {
    width: 90%;
    font-size: 30px;
    font-style: normal;
    font-family: inherit;
    text-align: left;
    font-weight: 500;
    opacity: 0;
    transform: translateX(100px);
    transition: all 0.3s;
  }
  .swiper-slide .title p {
    font-size: 12px;
    font-weight: 300;
    margin: 0 0;
    width: 90%;
    opacity: 0;
    transform: translateX(100px);
    transition: all 1s;
  }
}

.hide {
  opacity: 0;
}
</style>

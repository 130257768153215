<template>
  <div id="app">
    <div id="nav">
      <div class="logo">
        <img
          src="./assets/image/logo.png"
          alt=""
          title="贵州智联云弛软件科技有限公司"
        >
      </div>
      <div class="route">
        <el-menu
          class="el-menu-demo"
          mode="horizontal"
          @select="about"
        >
          <el-menu-item index="/">
            <router-link to="/">网站首页</router-link>
          </el-menu-item>
          <el-submenu index="/about">
            <template slot="title">
              <router-link to="/about">关于我们</router-link>
            </template>
            <el-menu-item
              v-for="about in aboutData"
              :key="about.name"
              :index="about.id"
            >
              <router-link
                tag="li"
                :to="{ name: 'About', params: { id: about.id } }"
              >
                {{about.name}}
              </router-link>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="/case">
            <template slot="title">

              <router-link to="/case">产品案例</router-link>
            </template>
            <el-menu-item
              v-for="cases in caseData"
              :key="cases.title"
              :index="cases.id"
            >
              <router-link
                tag="li"
                :to="{ name: 'Case', params: { id: cases.id } }"
              >
                {{cases.title}}
              </router-link>
            </el-menu-item>
          </el-submenu>

          <el-menu-item index="/contact">
            <router-link to="/contact">联系我们</router-link>
          </el-menu-item>
        </el-menu>
        <!-- <router-link to="/">网站首页</router-link>
        <router-link to="/about">关于我们</router-link>
        <router-link to="/case">产品案例</router-link>
        <router-link to="/contact">联系我们</router-link> -->
      </div>
      <el-button
        @click="drawer = true"
        class="btn"
      >
        <i class="el-icon-s-operation"></i>
      </el-button>

    </div>
    <el-drawer
      :visible.sync="drawer"
      size="100%"
    >
      <router-link to="/">网站首页</router-link>
      <router-link to="/about">关于我们</router-link>
      <router-link to="/case">产品案例</router-link>
      <router-link to="/contact">联系我们</router-link>
    </el-drawer>
    <router-view />
  </div>
</template>


<script>
export default {
  name: "App",
  data() {
    return {
      aboutData: [
        {
          name: "公司简介",
          id: "introduction",
        },
        {
          name: "发展历程",
          id: "course",
        },
        {
          name: "公司团队",
          id: "team",
        },
        {
          name: "公司活动",
          id: "activity",
        },
      ],
      caseData: [
        {
          id: "building",
          title: "智慧楼宇",
        },
        {
          id: "carving",
          title: "5G云雕刻",
        },
        {
          id: "party",
          title: "智慧党建",
        },
        {
          id: "fire",
          title: "智慧消防",
        },
        {
          id: "office",
          title: "智慧办公",
        },
        {
          id: "marathon",
          title: "智慧马拉松",
        },
        {
          id: "travel",
          title: "智慧旅游平台",
        },
        {
          id: "community",
          title: "智慧社区",
        },
        {
          id: "exhibition",
          title: "智慧展厅",
        },
        {
          id: "campus",
          title: "智慧校园",
        },
      ],
      serviceData: [
        {
          url: "http://www.10086.cn/index/gd/index_200_200.html",
          title: "中国移动通信",
        },
        {
          url: "https://www.china-tower.com/index",
          title: "中国铁塔",
        },
        {
          url: "http://www.sdlckj.com/",
          title: "力创科技",
        },
        {
          url: "https://www.cloudwalk.com/",
          title: "云从科技",
        },
        {
          url: "https://www.inspur.com/",
          title: "浪潮",
        },
        {
          url: "http://www.hxcy.com.cn/",
          title: "杭州华星创业通信",
        },
      ],
      drawer: false
    }
  },
  watch: { // watch的第一种写法
    $route(to, from) {
      this.activeIndex = from.path;
      if (to.name !== from.name) {
        this.drawer = false;
      }
    }
  },
  methods: {
    about(key, keyPath) {
      if (keyPath.length == 2) {
        this.$store.state.name = key;
      }
      this.$store.state.isConfigShow = !this.$store.state.isConfigShow;
    }
  }

}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
a,
li {
  text-decoration: none;
  list-style-type: none;
}
body::-webkit-scrollbar {
  display: none;
}
#app {
  #nav {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    width: 100vw;
    height: 60px;
    z-index: 11;
    background-color: white;
    .logo {
      img {
        max-width: 380px;
        max-height: 60px;
      }
    }
    .route {
      display: flex;
      .el-menu-item.is-active {
        border-bottom: 0;
      }
      .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
        border: 0;
      }
      a {
        font-weight: bold;
        color: #666;
        padding: 0 16px;
        height: 60px;
        overflow: hidden;
        line-height: 60px;
        background: url("./assets/image/ico2.png") no-repeat left center;
        &.router-link-exact-active {
          color: black;
        }
        &:first-child {
          background: none;
        }
        &:hover {
          color: #90c42f;
        }
      }
    }

    .btn {
      display: none;
      &:hover {
        background-color: transparent;
      }
      &:active {
        border: 1px solid #dcdfe6;
      }
      &:focus {
        background-color: transparent;
        border: 1px solid #dcdfe6;
      }
    }
  }
  .el-drawer__body {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    a {
      font-weight: bold;
      color: #666;
      padding: 0 16px;
      height: 60px;
      overflow: hidden;
      line-height: 60px;
      &.router-link-exact-active {
        color: black;
      }
      &:hover {
        color: #90c42f;
      }
    }
  }
}

@media (max-width: 950px) {
  #app {
    #nav {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      width: 100vw;
      z-index: 11;
      background-color: white;
      .logo {
        img {
          max-width: 300px;
          max-height: 60px;
        }
      }
      .route {
        display: none;
        a {
          font-weight: bold;
          color: #666;
          padding: 0 16px;
          height: 60px;
          overflow: hidden;
          line-height: 60px;
          background: url("./assets/image/ico2.png") no-repeat left center;
          &.router-link-exact-active {
            color: black;
          }
          &:first-child {
            background: none;
          }
          &:hover {
            color: #90c42f;
          }
        }
      }
      .btn {
        display: block;
        width: 50px;
        margin-right: 10px;
        padding: 15px;
        i {
          color: black;
          font-size: 20px;
          text-align: center;
        }
      }
    }
  }
}
</style>
